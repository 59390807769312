import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/.pnpm/@apollo+client-react-streaming@0.11.5_@apollo+client@3.11.8_react@18.3.1/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/Features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/Top10Books.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/TrendingReviewsThisWeek.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/shared/BookCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/shared/ReviewCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/shared/ShelvedBookCarousel.tsx");
