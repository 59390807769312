"use client";

import React from "react";
import MobileCarousel from "./MobileCarousel";
import BookCover from "./BookCover";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { useResponsive } from "@/hooks/useResponsive";
interface BookCarouselProps {
  showTitle?: boolean;
  showPositionInSeries?: boolean;
  books: Book[];
}
export const MobileBookCarousel: React.FC<BookCarouselProps> = ({
  books,
  showPositionInSeries,
  showTitle
}) => {
  const {
    isTablet
  } = useResponsive();
  const slidesToShow = isTablet ? 4 : 3;
  return <MobileCarousel className="lg:hidden" items={books} slidesToShow={slidesToShow} partialVisibleFraction={isTablet ? 0.75 : 0.35} renderItem={book => <div className={cn("pr-[10px] lg:hidden", books.length <= slidesToShow && "pr-0", showTitle && "flex flex-col gap-2")}>
          <div className="w-full aspect-[2/3]">
            <BookCover book={{
        images: book?.images || undefined,
        title: book?.title || undefined,
        slug: book?.slug || undefined
      }} className="rounded" mobileFallbackSize={28} />
          </div>
          {showTitle ? <Link href={book?.slug ? `/books/${book?.slug}` : ""} className="flex flex-col gap-2">
              <p className={cn("text-xs sm:text-base font-normal line-clamp-2 text-white overflow-hidden", books.length < slidesToShow && "text-sm font-bold")} title={book?.title as string}>
                {showPositionInSeries ? book?.positionInSeries ? <>
                      <span className="bg-[#1f202d] border rounded-[4px] px-1 py-0.5 border-[#5069ce]">
                        #{book?.positionInSeries ?? "-"}
                      </span>{" "}
                    </> : "" : null}
                {book?.title}
              </p>
            </Link> : null}
        </div>} data-sentry-element="MobileCarousel" data-sentry-component="MobileBookCarousel" data-sentry-source-file="MobileBookCarousel.tsx" />;
};