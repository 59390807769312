"use client";

import { cn } from "@/lib/utils";
import React, { ReactNode } from "react";
import Slider from "react-slick";
export interface MobileCarouselItem {
  id?: string | null;
}
interface CarouselProps<T extends MobileCarouselItem> {
  items: T[];
  renderItem: (item: T) => ReactNode;
  slidesToShow?: number;
  slidesToScroll?: number;
  partialVisibleFraction?: number;
  className?: string;
}
const Carousel = <T extends MobileCarouselItem,>({
  items,
  renderItem,
  slidesToShow = 3,
  slidesToScroll = 1,
  partialVisibleFraction = 0.35,
  className
}: CarouselProps<T>) => {
  const actualSlidesToShow = items?.length < slidesToShow + partialVisibleFraction ? items?.length : slidesToShow + partialVisibleFraction;
  const gridCols = `grid-cols-${slidesToShow}`;

  // console.log('actualSlidesToShow', actualSlidesToShow)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: actualSlidesToShow,
    slidesToScroll,
    arrows: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: actualSlidesToShow
      }
    }]
  };
  if (items?.length <= slidesToShow) {
    return <div className={cn("grid grid-cols-2 gap-4 pr-5", items.length === slidesToShow && "gap-3 grid-cols-3", gridCols, className)}>
        {items.map(item => <div key={item.id} className="px-0">
            {renderItem(item)}
          </div>)}
      </div>;
  }
  return <div className={className} data-sentry-component="Carousel" data-sentry-source-file="MobileCarousel.tsx">
      <Slider {...settings} className="overflow-hidden" data-sentry-element="Slider" data-sentry-source-file="MobileCarousel.tsx">
        {items.map(item => <div key={item.id} className="px-0">
            {renderItem(item)}
          </div>)}
      </Slider>
    </div>;
};
export default Carousel;