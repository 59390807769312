"use client";

import React, { ReactNode } from "react";
import Slider from "react-slick";
interface MobileShelvedBookCarouselProps<T> {
  items: T[];
  loading?: boolean;
  renderItem: (item: T, index: number) => ReactNode;
}
const MobileShelvedBookCarousel = <T extends {
  id?: string | number;
},>({
  items,
  renderItem
}: MobileShelvedBookCarouselProps<T>) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    // Adjust as needed
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 648,
      settings: {
        slidesToShow: 3.25
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4.75
      }
    }]
  };
  return <Slider {...settings} data-sentry-element="Slider" data-sentry-component="MobileShelvedBookCarousel" data-sentry-source-file="MobileShevedBookCarousel.tsx">
      {items.map((item, index) => <div key={item?.id ?? index}>{renderItem(item, index)}</div>)}
    </Slider>;
};
export default MobileShelvedBookCarousel;