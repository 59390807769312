"use client";

import React from "react";
import Link from "next/link";
import { FaChevronRight } from "react-icons/fa";
import { cn } from "@/lib/utils";
interface BookCarouselHeaderProps {
  title: string;
  url?: string;
  hideMore?: boolean;
  urlText?: string;
}
const BookCarouselHeader: React.FC<BookCarouselHeaderProps> = ({
  title,
  url,
  hideMore,
  urlText
}) => {
  return <div className={cn("mb-5 max-sm:mb-4 flex max-sm:justify-between items-center gap-5")} data-sentry-component="BookCarouselHeader" data-sentry-source-file="BookCarouselHeader.tsx">
      {url ? <Link className="text-xl sm:text-2xl font-semibold text-white" href={url ?? ""}>
          {title}
        </Link> : <h3 className="text-xl sm:text-2xl font-semibold text-white">
          {title}
        </h3>}
      {!hideMore && <Link href={url ?? ""} className="font-medium text-white flex sm:mt-0.5 items-center gap-1">
          <span className="max-sm:hidden">
            {urlText ? urlText : "See More"}
          </span>
          <span className="sm:hidden">{urlText ? urlText : "See All"}</span>
          {!urlText && <FaChevronRight className="max-sm:hidden" size={12} />}
        </Link>}
    </div>;
};
export default BookCarouselHeader;